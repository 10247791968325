export const fullStoryOrganizationId = process.env.NEXT_PUBLIC_FULLSTORY_ORGANIZATION_ID || '';
export const googleAnalyticsID = process.env.NEXT_PUBLIC_GA_ID || '';
export const googleTagManagerID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const nodeEnvironment = process.env.NODE_ENV || 'development';
export const isSearchEngineIndexable = process.env.SEARCH_ENGINE_INDEXABLE || 'false';
export const sentryDns = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
export const isBlogFeatureFlagEnabled = process.env.NEXT_PUBLIC_FEATURE_FLAG_BLOG_PAGE === 'true';
export const isCostPlusPageFeatureFlagEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_COSTPLUS_PAGE === 'true';
export const contentfulSpaceId = process.env.CONTENTFUL_SPACE_ID || '';
export const contentfulAccessToken = process.env.CONTENTFUL_ACCESS_TOKEN || '';
export const contentfulEnvironment = process.env.CONTENTFUL_ENVIRONMENT || 'develop';
